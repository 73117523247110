@use "../../scss/includes/colors" as color;

.page-title {
  color: color.$primary-color;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: 3% !important;
  margin-top: 2px;
  padding: 20px 20px 10px;
  width: 93%;

  &__text {
    color: color.$primary-color;
    font-size: 12px;
    font-weight: bold;
    margin-left: 2% !important;
  }

  &__logo {
    height: 100%;

    /*  &__imgLogo {
      height: 50% !important;
      width: 4%;
    } */
  }
}
