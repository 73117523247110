@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.sinader {
  height: 100%;
  margin-left: 2.5%;
  margin-top: 2%;
  overflow-y: auto;
  text-align: left;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    padding: 1.5%;
  }

  &__period {
    align-items: flex-end;
    color: color.$grey-light;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-bottom: 1.5%;
    width: 100%;

    &__text {
      margin-bottom: 0.5%;
    }
  }

  &__table {
    background-color: color.$grey-background;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgb(196 195 197 / 100%);
    height: 70%;
    margin-bottom: 5%;
    padding: 1%;
    position: relative;

    &__btns {
      align-items: center;
      bottom: 40px;
      display: flex;
      flex-direction: row;
      height: 10%;
      padding: 7px;
      width: 100%;
      z-index: 1;

      &__all {
        display: flex;
        flex-direction: row;
        justify-content: right;
        width: 90%;
      }

      &__btn {
        display: flex;
        flex-direction: row;
        height: 100% !important;
        justify-content: left !important;
        width: 10%;

        &__logo {
          height: 95% !important;
        }
      }
    }
  }

  &__legend {
    display: grid;
    grid-template-columns: auto auto;

    &__item {
      color: color.$black-color;
      display: flex;
      flex-direction: row;
      font-weight: bold;
      margin-bottom: 1%;

      &__primary {
        background-color: #74b9ef;
        margin-right: 1%;
        width: 4%;
      }

      &__secondary {
        background-color: color.$secondary-color;
        margin-right: 1%;
        width: 4%;
      }

      &__primary-light {
        background-color: #c1dcf1;
        margin-right: 1%;
        width: 4%;
      }

      &__secondary-light {
        background-color: #b3f4ed;
        margin-right: 1%;
        width: 4%;
      }
    }
  }
}

.expand {
  display: none;
  height: 1000px;
  position: fixed;
  width: 2000px;
}

.open-expand {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.full-height {
  border: 15px solid color.$secondary-color;
  border-radius: 30px;
  height: 100%;
  padding: 1%;
}

.error {
  align-items: center;
  background: rgb(0 0 0 / 25%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__main {
    background-color: color.$white-color;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    display: block;
    height: auto !important;
    max-height: 80% !important;
    overflow: scroll;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    position: relative;
    width: 30%;

    &__title {
      align-items: center;
      background-color: color.$white-color;
      border-bottom: 1px solid color.$grey-light;
      color: color.$grey-light;
      display: flex;
      flex-direction: row;
      font-size: font.$title-size;
      font-weight: font.$title-weight;
      justify-content: space-between;
      margin-bottom: 3%;
      padding: 3% 0%;
      position: sticky;
      text-align: left;
      top: 0;

      &__text {
        left: 0;
      }

      &__close {
        color: color.$grey-intermediate;
        cursor: pointer;
      }
    }

    &__text {
      color: color.$grey-light;
      display: flex;
      flex-direction: column;
      font-size: font.$subtitle-size;
      margin-top: 5%;
      text-align: left;

      &__label {
        color: color.$grey-dark;
      }

      &__detail {
        color: color.$grey-intermediate;
        margin-bottom: 5%;
      }
    }
  }
}
