@use "../../../scss/includes/colors" as color;

.filters_search {
  display: flex;
  position: sticky;

  &__filters_input {
    background-color: color.$grey-background;
    border: 1px solid color.$grey-background;
    border-radius: 15px;
    padding: 12px;
    width: 100%;
  }
}
