@use "../../../scss/includes/colors" as color;

ol {
  margin-bottom: 0 !important;

  li {
    .ctItems {
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &:hover {
        background-color: color.$grey-light-plus;
      }
    }

    .lg-name {
      width: 160px;
    }

    .lg-type {
      color: color.$danger-color;
    }
  }
}

.role_access_title {
  color: color.$primary-color;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.role_access_title:first-child {
  margin-top: 10px;
}
