@use "../../../scss/includes/colors" as color;

.notifications {
  /* overflow-y: scroll; */
  height: 60%;

  &__titulo-card {
    color: color.$black-color;
    font-size: 14px;
    font-weight: bold;
    margin-left: 3%;
    margin-top: 3%;
  }

  &__grid-container {
    color: color.$black-color;
    display: grid;
    font-weight: bold;
    gap: 2%;
    grid-template-columns: 32% 32% 32%;
    height: 95%;
    margin: 0 auto;
    margin-left: 3%;
    margin-top: 2%;
    width: 94%;

    &__item {
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      height: 90% !important;
    }
  }
}

/* .notifications-grid-container > div {
  background-color: color.$grey-light;
  border-radius: 15px;
  color: color.$black-color;
  display: inline-block;
  font-size: 12px;
  height: 100%;
  margin-right: 2%;
  font-weight: bold;
  border: 1px solid red;

  &__title {
    margin-bottom: 3%;
  }
} */
