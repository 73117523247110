@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.link {
  color: font.$button-color;
}

.download-register-files {
  &--content {
    &--text-button {
      padding: 0;
      width: 15rem;

      &--image {
        font-size: x-large;
        margin: 0 0.7rem 0 0;
      }

      &--text {
        text-decoration: underline;
      }
    }

    &--text-button a {
      align-items: center;
      color: color.$black-light;
      display: flex;
      font-weight: 500;
      margin: 3px 0;
      vertical-align: middle;
      width: 100%;
    }
  }
}
