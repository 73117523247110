@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.qr-item {
  background-color: color.$secondary-color;
  height: 100%;
  width: 100%;

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    width: 100% !important;

    &__logo {
      max-width: 0;
      width: 50% !important;
    }

    &__data {
      flex-grow: 1;
      overflow: auto; /* Add scroll if content overflows */
      width: 100%;
    }
  }
}
