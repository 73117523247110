@use "../../scss/includes/colors" as color;

.logo_figure {
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;
  margin-top: -3px;
  position: relative;
  width: 100%;

  &__img {
    display: block;
    height: auto;
    margin: auto;
    margin-bottom: 5%;
    width: 80%;

    &__deffault {
      max-width: 300px;
    }

    &__header {
      width: 100%;
    }

    &__menu {
      background-color: color.$grey-light;
      max-width: 20px;
    }

    &__footer {
      max-width: 20px;
    }
  }
}
