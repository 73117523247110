* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  background: none;
  border: unset;
  font-family: var(--font-noto);
}
