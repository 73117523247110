@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.table-container {
  background-color: color.$grey-background;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgb(196 195 197 / 100%);
  height: auto !important;
  margin-bottom: 2%;
  overflow-x: scroll;
  overflow-y: scroll;
  position: relative;
  width: 100%;

  &__table {
    margin-bottom: 2%;
    min-width: 100%;
    table-layout: fixed;
    width: 100%;

    &__thead {
      background-color: color.$grey-dark;
      color: color.$white-color;
      position: sticky !important;
    }

    &__thead__tr__th {
      border: 1px solid color.$grey-light;
      font-weight: 500 !important;
      overflow: hidden;
      padding: 10px;
      text-align: center;
      text-overflow: ellipsis !important;
    }

    &__tbody__tr.final_line {
      border-top: 3px solid color.$white-color !important;
    }

    &__tbody__tr {
      background-color: color.$white-color;
      border-bottom: 1px solid color.$grey-light;
      height: 50px;

      &:hover {
        background-color: color.$grey-background;
      }
    }

    &__tbody__tr__td {
      border: 1px solid color.$grey-light;
      color: color.$grey-dark;
      cursor: auto;
      padding: 5px;
      text-align: center;
      text-overflow: ellipsis !important;

      &.editable {
        background-color: #b3f4ed;
        cursor: pointer;
      }

      &.clicked {
        background-color: color.$secondary-color;
        cursor: pointer;
      }

      &.auto_ses_act {
        background-color: color.$primary-color;
      }

      &.man_ses_act {
        background-color: color.$secondary-color !important;
      }

      &.auto_ses_ant {
        background-color: #c1dcf1 !important;
      }

      &.man_ses_ant {
        background-color: #b3f4ed !important;
      }
    }

    &__tbody__tr__td__span {
      color: color.$grey-dark;
      font-weight: thin;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.isEditable {
        color: color.$grey-dark;
      }
    }

    &__tbody__tr__td__input {
      text-align: center;
      width: 100px !important;
      width: 100% !important;
    }

    &__tbody__tr__td__lst {
      border: 0;
      color: color.$grey-dark;
      text-align: center;
      width: 100% !important;
    }

    &__tbody__tr__td__icon {
      color: color.$grey-intermediate;
      cursor: pointer;
    }
  }

  &__btn {
    background-color: color.$grey-intermediate;
    border-radius: 15px;
    color: color.$white-color;
    position: absolute;
    width: 20%;
  }
}

.big {
  text-align: left;
  width: 15% !important;
}

.bottom-border {
  border-bottom: 4px solid color.$grey-intermediate !important;
}
