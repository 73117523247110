@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.evolution {
  display: flex;
  flex-direction: column;
  height: 86%;
  margin: 0 auto;
  margin-top: 2%;
  min-height: 400px;
  overflow-y: auto;
  position: relative;
  text-align: left;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    height: auto;
    padding: 1.5%;
  }

  &__chart-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 51%;
    margin-bottom: 3%;
    min-height: 650px;
    position: relative;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      height: 350px;
      margin-top: 20px !important;
    }

    &__filters {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      height: 11%;
      margin-bottom: 1%;
      min-height: 50px;
      overflow-x: hidden;
      position: relative;
      width: 100% !important;

      @media screen and (max-width: mixins.$mobile-max-width) {
        align-items: flex-start; // Alinea el contenido a la izquierda
        flex-direction: column-reverse;
        height: 30%;
        justify-content: flex-start;
        margin-bottom: 3%;
      }

      &__materials {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        position: relative;
        width: 70% !important;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 50%;
          width: 100% !important;
        }

        &__title {
          align-items: center;
          color: color.$grey-intermediate;
          display: flex;
          font-weight: font.$title-weight;
          height: 40%;
          text-align: left;
          width: 100%;

          &__checkbox {
            margin-right: 1%;
          }

          @media screen and (max-width: mixins.$mobile-max-width) {
            height: 30%;
            margin-left: auto;
            text-align: right;
          }
        }

        &__filter {
          background-color: color.$grey-background;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          height: 60%;
          overflow-x: hidden;
          position: relative;
          width: 100% !important;

          @media screen and (max-width: mixins.$mobile-max-width) {
            height: 65%;
            margin-top: 2%;
            max-width: 100vw;
            width: 100%;
          }

          &__list {
            background-color: color.$grey-background;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            height: 100%;
            margin-right: auto;
            overflow-x: hidden;
            white-space: nowrap;
            width: 90% !important;

            &__logo {
              height: 100%;

              &__unselected {
                height: 100%;
                opacity: 0.2;
              }
            }
          }

          &__arrow-btn {
            align-items: center;
            color: color.$grey-intermediate;
            cursor: pointer;
            display: flex;
            height: 90%;
            justify-content: center;
            text-align: center;
            width: 5%;
          }
        }
      }

      &__date-filter {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: auto;
        width: 28%;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 48%;
          margin-bottom: 2%;
          margin-left: 0;
          width: 50%;
        }

        &__title {
          align-items: center;
          color: color.$grey-intermediate;
          display: flex;
          font-weight: font.$title-weight;
          height: 40%;
          text-align: left;
          width: 100%;

          @media screen and (max-width: mixins.$mobile-max-width) {
            width: 100%;
          }
        }

        &__content {
          display: flex;
          flex-direction: row;
          height: 60%;
          justify-content: space-between;
          width: 100%;

          &__date-picker {
            color: font.$subtitle-color !important;
            float: right;
            font-size: font.$subtitle-size !important;
            height: 100%;
            width: 80% !important;

            @media screen and (max-width: mixins.$mobile-max-width) {
              width: 60%;
            }
          }

          &__more {
            align-items: center;
            background-color: color.$grey-background;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            height: 100% !important;
            justify-content: center;
            overflow-x: hidden;
            position: relative;
            width: 15% !important;

            &__icon {
              align-items: center;
              background-color: color.$grey-background;
              border-radius: 5px;
              display: flex;
              flex-direction: row;
              height: 60% !important;
              justify-content: center;
              overflow-x: hidden;
              position: relative;
              width: 100% !important;

              @media screen and (max-width: mixins.$mobile-max-width) {
                width: 60%;
              }
            }
          }
        }
      }
    }

    &__chart {
      background-color: color.$grey-background;
      border-radius: 15px;
      height: 78%;
      overflow-y: auto;
      padding: 20px;
      position: relative;
      width: 100%;

      canvas {
        background-color: color.$white-color;
        border-radius: 15px;
        grid-gap: 10px;
        justify-content: center !important;
        overflow-x: scroll;
        padding: 10px;
        width: 100%;
      }

      @media screen and (max-width: mixins.$mobile-max-width) {
        height: 250px !important;
        padding: 10px;
      }
    }

    &__btns {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 5% !important;
      justify-content: flex-end;
      width: 100%;

      &__item {
        height: 100%;

        &__icon {
          align-self: flex-start;
          height: 100%;
          margin-right: auto;
        }
      }
    }

    &__btn {
      background-color: color.$primary-color;
      border-radius: 15px !important;
      color: color.$white-color;
      height: 5% !important;
      margin-top: 2% !important;
      width: 100% !important;
    }
  }

  &__others {
    display: grid;
    gap: 5%;
    grid-template-columns: 55% 40%;
    height: 46%;
    min-height: 600px;

    @media screen and (max-width: mixins.$mobile-max-width) {
      display: block;
    }
  }

  &__popover {
    display: flex;
    flex-direction: column;

    &__container {
      display: flex;
      flex-direction: column;
      width: 10vw !important;

      @media screen and (max-width: mixins.$mobile-max-width) {
        width: 30vw !important;
      }
    }
  }
}

@media screen and (max-width: mixins.$mobile-max-width) {
  .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-picker-cell-inner {
  font-size: 11px;
}

.expandedChart {
  align-items: center;
  background-color: color.$secondary-color;
  display: flex;
  height: 100vh; /* Cambié a viewport height */
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw; /* Cambié a viewport width */
  z-index: 100;

  &__container {
    align-items: center;
    background-color: color.$white-color;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 95vw !important;
    justify-content: center;
    margin: 0 !important;
    padding: 0% 3%;
    position: absolute;
    transform: rotate(90deg);
    transform-origin: center center;
    width: 90vh !important;

    &__chart {
      align-items: center;
      display: flex;
      height: 70vw;
      justify-content: center;
      width: 80vh;
    }
  }
}
