.connection {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 100;

  span {
    color: #fff;
  }
}

.online {
  background-color: #65c05a;
}

.offline {
  background-color: #ce3e50;
}
