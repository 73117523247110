@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;

.left-header {
  background-color: color.$white-color;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 15% !important;
  margin: 0 auto;
  min-height: 100px;
  position: relative;
  width: 100%;

  &__logo {
    align-items: center !important;
    background-color: color.$white-color;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: inline-block;
    height: 100%;
    justify-content: center;
    padding: 3%;
    width: 100%;

    &__img {
      border-radius: 10px;
      cursor: pointer;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}

.misinmuebles {
  background-color: color.$primary-color;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: block;
  height: 85% !important;
  margin: 0 auto;
  min-height: 300px !important;
  overflow: auto;
  position: relative;
  width: 100%;

  &__user {
    align-items: center;
    border: 1px solid color.$white-color;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: color.$white-color;
    display: flex;
    font-size: font.$subtitle-size;
    height: 7%;
    min-height: 40px;

    &__name {
      margin-left: 5%;
      overflow: hidden;
      text-overflow: ellipsis; /* Agrega puntos suspensivos al final del texto que no cabe */
      white-space: nowrap; /* Evita el salto de línea */
      width: 60%;
    }

    &__role {
      font-weight: bold;
      margin-right: 5%;
      overflow: hidden !important; // Oculta el texto que desborda el contenedor
      text-align: right;
      text-overflow: ellipsis; // Agrega puntos suspensivos al texto que no cabe
      width: 30%;
    }
  }

  &__titleContainer {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 5%;
    min-height: 40px;
    width: 90%;

    &__title {
      color: color.$white-color;
      font-size: font.$title-size;
      font-weight: bold;
      margin: 0;
    }
  }

  &__listcontainer {
    display: flex;
    flex-direction: column;
    height: 75%;
    min-height: 250px !important;

    &__list {
      background-color: color.$white-color;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: font.$subtitle-size;
      height: 96%;
      margin: 0 auto;
      margin-top: 4%;
      min-height: 90px;
      min-height: 160px !important;
      padding-bottom: 5%;
      width: 90%;

      &__search {
        align-content: center;
        align-items: center;
        background-color: color.$white-color;
        border-radius: 15px;
        box-shadow: 0 3px 6px #00000029;
        color: color.$white-color;
        display: flex;
        height: 7%;
        margin-bottom: 3% !important;
        min-height: 30px;
        text-align: center;
        width: 100% !important;

        &__searchbar {
          background-color: color.$white-color;
          border: 0;
          border-radius: 5px;
          color: color.$primary-color;
          font-size: font.$subtitle-size;
          height: 95% !important;
          margin-left: 4%;
          outline: none;
          text-align: center;
          text-align: left;
          width: 90%; /* 80% */
        }

        &__searchbar::placeholder {
          color: color.$primary-color; /* Reemplaza "#aaa" con el color deseado */
        }

        &__icon {
          display: flex;
          flex-direction: row;
          float: right;
          height: 100% !important;
          width: 10%;

          &__img {
            color: color.$primary-color;
            cursor: pointer;
            height: 100%;
            max-width: 30px;
            width: 50%;
          }
        }
      }

      &__selected {
        align-content: flex-start;
        display: flex;
        flex-shrink: 0; /* Evita que este contenedor se contraiga */
        flex-wrap: wrap;
        height: auto !important;
        margin-bottom: 3% !important;
        margin-left: 5%;
        max-height: 10%;
        min-height: 5%;
        overflow: auto;
        width: 90%;

        &__tag {
          border: 0.5px solid color.$grey-light;
          color: color.$grey-light;
          display: flex;
          flex-direction: row;
          margin-bottom: 1%;
          margin-right: 1%;
          padding: 1%;
          white-space: nowrap;
          width: auto;

          &__logo {
            margin-right: 2%;
          }
        }
      }

      &__inmuebleslist {
        flex-grow: 1; /* Ocupa el espacio restante */
        margin: 0 auto;
        min-height: 120px !important;
        overflow-y: scroll;
        width: 95%;

        &__client {
          border: 1px solid color.$primary-color;
          border-radius: 8px;
          color: color.$white-color;
          display: flex; // Habilitar flexbox
          flex-direction: column; // Alinear elementos verticalmente
          font-weight: bold;
          height: auto;
          justify-content: space-between; // Espaciar el nombre y el footer
          margin-bottom: 2%;

          &__name {
            align-items: center;
            background-color: color.$primary-color;
            border-radius: 8px 8px 0 0;
            display: flex;
            flex-direction: row;
            height: 10% !important;
            justify-content: space-between;
            min-height: 30px;
            padding: 0 3%;
          }

          &__properties {
            // Aquí puedes agregar contenido o estilos para la sección del contenido
            flex-grow: 1;
            overflow-y: scroll;
            padding: 2% 1%;
          }

          &__footer {
            align-items: center;
            background-color: color.$primary-color;
            border-radius: 0 0 5px 5px;
            display: flex;
            flex-direction: row;
            height: 5%;
            min-height: 15px;
            padding: 0 2%;
          }
        }

        &__clientclose {
          border-bottom: 1px solid color.$grey-background;
          border-radius: 8px;
          color: color.$primary-color;
          display: flex; // Habilitar flexbox
          flex-direction: column; // Alinear elementos verticalmente
          font-weight: bold;
          height: 6%;
          justify-content: space-between; // Espaciar el nombre y el footer
          margin-bottom: 2%;
          min-height: 30px;
          overflow-y: scroll;

          &__name {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: space-between;
            padding: 0 3%;
          }
        }

        &__label {
          color: color.$primary-color;
          font-size: font.$subtitle-size;
          margin-bottom: 5%;
        }

        &__checkbox {
          border: 1px solid color.$primary-color !important; /* Reemplaza "#007bff" con el color deseado */
          cursor: pointer;
        }

        :checked {
          border: 1px solid color.$primary-color; /* Establecer el borde */
          color: color.$white-color;
        }
      }
    }
  }

  /* ::-webkit-scrollbar {
    display: none;
  } */

  &__logo-container {
    align-items: center;
    display: flex;
    height: 12%;
    justify-content: center;
    min-height: 50px !important;
    width: 100%;

    &__logo-pryma {
      max-height: 100%;
      width: 30%;
    }

    &__button {
      max-height: 100%;
      width: 90%;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.property-filters {
  background-color: color.$white-color;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  height: 50%;
  left: 0;
  padding: 1%;
  position: fixed;
  top: 0;
  width: 21%;
  z-index: 2;

  &::before {
    border-bottom: 30px solid color.$white-color;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    content: "";
    height: 0;
    left: 5%;
    position: absolute;
    top: -10px;
    transform: translateX(-5%);
    width: 0;
  }

  &__header {
    color: color.$grey-light;
    display: flex;
    height: 5%;
    justify-content: right;
    margin-bottom: 3%;
    width: 100%;
  }

  &__title {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin-bottom: 3%;
    width: 100%;
  }

  &__subtitle {
    color: color.$grey-light;
    margin-bottom: 5%;
  }

  &__section {
    height: auto;
    margin-bottom: 5%;
    max-height: 25%;

    &__title {
      align-items: center;
      border-bottom: 1px solid color.$grey-background;
      color: color.$grey-light;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2%;
      padding-bottom: 2%;

      &__icon {
        cursor: pointer;
      }
    }

    &__content {
      display: grid;
      gap: 2%;
      grid-template-columns: repeat(2, 1fr);
      overflow: auto;

      &__item {
        background-color: color.$primary-color;
        border-radius: 15px !important;
        color: font.$button-color;
        cursor: pointer;
        font-size: font.$button-size;
        height: 25px;
        text-align: center;
      }
    }

    &__list {
      color: color.$grey-light !important;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto !important;
    }

    &__input {
      align-items: center;
      border: 1px solid color.$grey-light;
      border-radius: 5px;
      color: color.$grey-light;
      padding: 3%;
      width: 100%;

      &::placeholder {
        color: color.$grey-light;
        font-weight: 50;
      }
    }
  }

  &__footer {
    align-items: center;
    border-top: 1px solid color.$grey-background;
    bottom: 2%;
    color: color.$grey-light;
    display: flex;
    flex-direction: row;
    height: auto;
    padding-top: 2%;
    position: absolute;
    width: 100%;

    &__text {
      margin-right: 3%;
      width: 74%;
    }

    &__button {
      background-color: color.$primary-color;
      border-radius: 50% !important;
      color: color.$white-color;
      height: 100%;
      height: 40px;
      overflow: hidden;
      width: 40px;
    }

    &__cancel-btn {
      background-color: color.$grey-light;
      color: color.$white-color;
      margin-right: 10% !important;
      width: 40%;
    }

    &__save-btn {
      background-color: color.$primary-color;
      color: color.$white-color;
      width: 40%;
    }
  }
}

.logo-contain {
  object-fit: contain !important;
}
