@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.profile-body {
  height: 100% !important;
  overflow: scroll;
  text-align: left;

  &__edit-grid-container {
    display: grid;
    gap: 4%;
    grid-template-columns: repeat(2, 1fr);
    height: 70%;
    margin-left: 10%;
    width: 80%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      gap: 0% !important;
      grid-template-columns: repeat(1, 1fr);
    }

    &__item {
      @media screen and (max-width: mixins.$mobile-max-width) {
        height: 500px;
      }

      &__img {
        display: inline-block;
        height: 3%;
      }

      &__title {
        color: font.$subtitle-color !important;
        display: inline-block;
        font-size: font.$title-size;
        font-weight: bold;
        margin-left: 2%;
      }

      &__edit-img-container {
        align-items: center;
        background-color: color.$grey-background;
        border-radius: 10px;
        display: flex;
        height: 10% !important;
        margin-top: 5%;
        width: 100%;

        &__img {
          display: inline-block;
          height: 90%;
          margin-left: 3%;
        }

        &__text {
          color: font.$subtitle-color !important;
          display: inline-block;
          font-size: font.$subtitle-size;
          margin-left: 3%;
        }
      }

      &__edit-info-container {
        background-color: color.$grey-background;
        border-radius: 10px;
        height: auto !important;
        margin-top: 5%;

        &__not {
          height: 40%;
          padding: 10% !important;

          &__title {
            color: font.$subtitle-color;
            font-size: font.$subtitle-size;
            font-weight: bold;
            line-height: 1 !important;
            margin-bottom: 10%;
            padding: 0;
          }

          &__option {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 5%;

            &__text {
              color: font.$subtitle-color;
              font-size: font.$subtitle-size;
              line-height: 1 !important;
              padding: 0;
            }

            &__toggle {
              border-radius: 25px !important;
            }
          }
        }
      }
    }
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
