@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;

.bzeroindex-card {
  color: font.$title-color;
  display: flex;
  flex-direction: column;
  height: 15% !important;
  min-height: 200px;
  text-align: left;

  &__titulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    display: block;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    height: 20px !important;
  }

  &__subtitulo-card {
    border-radius: 15px !important;
    display: block;
    font-size: font.$subtitle-size;
    font-weight: font.$title-weight;
    height: 27px;
    text-align: left;

    &__icon {
      margin-left: 2%;
    }
  }

  &__content {
    background-color: color.$grey-background;
    border-radius: 15px !important;
    display: flex;
    flex-direction: column;
    height: 85%;
    justify-content: center !important;
    overflow-y: hidden;
    text-align: center;

    &__chart {
      margin-bottom: -10%;
      margin-left: 12.5%;
      margin-top: -10%;
      position: relative;
      width: 75%;

      &__number {
        color: color.$grey-intermediate;
        font-weight: font.$title-weight;
        left: 50%; /* Mueve el elemento al 50% del ancho del contenedor */
        position: absolute;
        top: 65%; /* Ajusta según lo necesites */
        transform: translateX(-50%) !important;
      }

      &__inf {
        color: color.$grey-intermediate;
        font-size: font.$subsubtitle-size;
        left: -5%;
        position: absolute;
        top: 70%;
        transform: translate(-50%, -50%);
      }

      &__sup {
        color: color.$grey-intermediate;
        font-size: font.$subsubtitle-size;
        left: 107%;
        position: absolute;
        top: 70%;
        transform: translate(-50%, -50%);
      }
    }

    &__message {
      color: color.$grey-intermediate;
      font-size: font.$subtitle-size;
      font-weight: bold;
    }
  }
}

.index-certificate {
  font-size: 11px !important;
  height: 120px !important;
}

.mini-number-certificate {
  font-size: 6px !important;
}
