@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

$skyblue: #f3faff;
$bluelight: #c1dcf1;
$greenlight: #b3f4ed;
$bluedark: #74b9ef;
$greendark: #42e3d4;
$greycolor: #c4c3c5;
$black: #707070;
$whitecolor: #f3f2f5;
$whitecolordark: #f5f5f5;
$rowfilled: #def2f0;

.tbl {
  height: 90% !important;
  overflow: auto;
  overflow-y: scroll;
  position: relative;
  width: 100%;

  &__editable {
    cursor: pointer;
    margin-bottom: 20px;
    min-width: 100%;
    table-layout: fixed;
    width: 100%;

    &__thead {
      background-color: color.$grey-intermediate;
      border: 1px solid color.$grey-light;
      color: color.$white-color;
      position: sticky !important;
    }

    &__thead__tr__th {
      font-weight: 500 !important;
      overflow: hidden;
      padding: 10px;
      text-overflow: ellipsis !important;
    }

    &__thead__tr_x_th__text {
      font-size: font.$subsubtitle-size;
    }

    &__tbody__tr.final_line {
      border-top: 3px solid $whitecolor !important;
    }

    &__tbody__tr {
      border-bottom: 1px solid $greycolor;

      &:hover {
        background-color: $whitecolordark;
      }
    }

    &__tbody__tr__td {
      border: 1px solid $greycolor;
      padding: 5px;

      &.auto_ses_act {
        background-color: $bluedark;
      }

      &.man_ses_act {
        background-color: $greendark !important;
      }
    }

    &__tbody__tr__td__span {
      color: color.$grey-dark;
      font-size: font.$subsubtitle-size;
      font-weight: thin;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.isEditable {
        color: color.$grey-dark;
      }
    }

    &__tbody__tr__td__input {
      font-size: 12px !important;
      width: 100px !important;
      width: 100% !important;
    }

    &__tbody__tr__td__lst {
      background-color: color.$grey-background;
      border: 0;
      color: color.$grey-dark;
      font-size: font.$subtitle-size;
      text-align: left;
      width: 100% !important;
    }

    &__tbody__tr__td__icon {
      color: #c4c3c5;
      cursor: pointer;
    }
  }
}

.tbl thead {
  position: sticky !important;
  top: 0;
}

.editColumn {
  width: 4%;
}

.Tratamiento-default {
  width: 50%;
}
