@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.recolection-card {
  height: 100% !important;
  min-height: 270px;
  overflow-y: auto;

  @media screen and (max-width: mixins.$mobile-max-width) {
    height: 300px !important;
    margin-bottom: 5%;
    min-height: 300px;
  }

  &__titulo-card {
    color: font.$title-color;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    height: 20px;
    text-align: left;
  }

  &__subtitulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    color: font.$title-color;
    display: flex;
    font-size: font.$subtitle-size;
    font-weight: font.$title-weight;
    height: 27px;
    margin-bottom: 2%;
    text-align: left;

    &__subtitle {
      width: 70%;
    }

    &__date {
      align-items: center;
      background-color: color.$grey-background;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 1%;
      text-align: right;
      width: 30%;

      &__select {
        background-color: color.$grey-background;
        border: 0;
        color: color.$black-color;
        width: 100%;
      }
    }
  }

  &__grid-container {
    display: grid;
    gap: 2%;
    grid-auto-rows: 20%;
    grid-template-columns: repeat(3, 1fr);
    height: calc(100% - 47px);
    overflow-y: auto;
    text-align: left;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      gap: 4%;
      grid-auto-rows: 46%;
    }
  }

  &__grid-container > div {
    color: color.$grey-dark;
    display: inline-block;
    font-size: 8px;
    max-height: 150px;
    min-height: 100px;
  }
}
