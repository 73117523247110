@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.ranking-card {
  display: flex;
  flex-direction: column;
  height: 20%;
  min-height: 310px;
  position: relative;
  text-align: left;

  @media screen and (max-width: mixins.$mobile-max-width) {
    height: auto;
    min-height: 250px;
  }

  &__titulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    display: block;
    font-size: font.$title-size;
    font-weight: font.$title-weight;
    height: 20px !important;
  }

  &__subtitulo-card {
    background-color: color.$white-color;
    border-radius: 15px !important;
    display: block;
    font-size: font.$subtitle-size;
    font-weight: font.$title-weight;
    height: 27px !important;
    text-align: left;
  }

  &__content-container {
    background-color: color.$grey-background;
    border-radius: 15px !important;
    display: block;
    height: 90%;
    padding: 5%;
    position: relative;

    &__content {
      height: 100%;
      margin-bottom: 3%;
      overflow-y: scroll;

      &__circle-img {
        position: absolute;
      }

      &__podio-container {
        display: grid;
        gap: -8%;
        grid-template-columns: repeat(3, 1fr);
        height: 62%;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 160px;
        }

        &__item {
          align-items: center;
          align-self: end;
          background-color: color.$white-color;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          height: 60%;
          justify-content: center;
          margin-left: -2.5%;
          position: relative;
          text-align: center;

          &__img {
            height: 80%;
            left: 50%;
            position: absolute;
            top: -60% !important;
            transform: translateX(-50%) !important;
          }

          &__name {
            align-items: center; /* Centrar verticalmente */
            bottom: 10%;
            display: flex;
            font-size: font.$subsubtitle-size;
            height: 35%;
            justify-content: center; /* Centrar horizontalmente */
            line-height: 1em;
            overflow: hidden;
            position: relative;
            text-align: center;
            white-space: normal;
            width: 80%;
          }

          &__value {
            align-items: center !important;
            background-color: color.$secondary-color;
            border-radius: 10px;
            bottom: 8% !important;
            color: color.$white-color;
            display: flex;
            font-size: font.$title-size;
            font-weight: font.$title-weight;
            height: 28%;
            justify-content: space-between;
            padding: 10%;
            position: absolute;
            width: 90%;

            &__number {
              color: color.$white-color;
            }

            &__percentage {
              color: color.$white-color;
              float: right;
              font-size: font.$subsubtitle-size;
              text-align: right;
            }
          }
        }
      }

      &__list-container {
        background-color: color.$white-color;
        border-radius: 15px;
        margin-top: 5%;

        &__element {
          align-items: center;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          box-shadow: 0 4px 4px -2px #00000029;
          display: flex;
          flex-direction: row;
          height: 30px;
          justify-content: center; /* Centrar horizontalmente */

          &__info {
            align-items: center !important;
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 90%;

            &__number {
              align-items: center !important;
              display: flex;
              font-size: font.$title-size;
              font-weight: font.$title-weight;
              height: 100%;
              margin-right: 5%;
            }

            &__img {
              align-items: center !important;
              display: flex;
              height: 25%;
              margin-right: 2%;
            }

            &__name {
              font-size: font.$subsubtitle-size;
              font-weight: font.$subsubtitle-weight;
              margin-right: 5%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 50%;
            }

            &__percentage {
              align-items: center !important;
              display: flex;
              font-size: font.$subsubtitle-size;
              font-weight: font.$subsubtitle-weight;
              width: 25%;
            }
          }
        }
      }
    }

    &__alert {
      align-items: center;
      background-color: color.$grey-background; /* rgb(243 242 245 / 85%); */
      border-radius: 15px;
      color: color.$grey-intermediate;
      display: flex;
      flex-direction: row;
      font-weight: bold;
      height: 100%;
      left: 0;
      padding: 10%;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}

.ranking-less {
  height: 10%;
}

.firstPlace {
  box-shadow:
    0 2px 2px -1px #00000029,
    2px 0 2px -1px #00000029,
    -2px 0 2px -1px #00000029;
  margin-bottom: 5%;
  margin-left: -2%;
  margin-right: -2%;
  transform: translateZ(10px);
  z-index: 1;
}
