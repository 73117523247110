@use "../../scss/includes/colors" as color;

.formbuilder-table-container {
  background-color: color.$white-color;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0.8rem;

  // font-size: 1vw;

  &--row {
    border-bottom: 1px solid color.$grey-intermediate;
    display: flex;
    gap: 10px;
    justify-content: space-around;

    &--element input {
      appearance: none;
      box-sizing: border-box;
      width: 100%;
    }

    &--element input:disabled {
      appearance: none;
      border: 0;
    }

    &--element input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &--element [type="number"] {
      max-width: 40px;
    }

    &--element select {
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &--element select:disabled {
      appearance: none;
      border: 0;
    }
  }

  &--last-row {
    border-bottom: 0;
  }
}
