@use "../../../../../scss/includes/colors" as color;
@use "../../../../../scss/includes/fonts" as font;
@use "../../../../../scss/includes/mixins" as mixins;

.calendarRequest {
  align-items: center;
  background: rgb(0 0 0 / 50%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__main {
    background-color: color.$white-color;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1%;
    height: auto !important;
    margin: auto;
    min-width: 450px;
    padding: 15px 20px;
    position: relative; /* Agrega posicionamiento relativo */
    width: 28%;

    &__menu {
      align-items: center;
      color: color.$grey-light;
      display: flex;
      flex-direction: row;
      height: 15px;
      justify-content: space-between;
      justify-content: flex-end;
      width: 100%;
    }

    &__header {
      display: flex;
      flex-direction: column;
      height: auto;

      &__select {
        width: 80%;

        &__title {
          align-items: center;
          color: color.$grey-dark;
          display: flex;
          flex-direction: row;
          font-weight: bold;
          gap: 3%;
          height: 100%;
          width: 100%;

          &__circle {
            border-radius: 50%;
            height: 10px;
            width: 10px;
          }
        }
      }

      &__date {
        color: color.$grey-intermediate;
        display: flex;
        flex-direction: row;
        gap: 3%;
        justify-content: space-between;
        margin-left: 10px;
        width: 100%;
      }
    }

    &__form {
      color: color.$grey-dark;
      display: flex;
      flex-direction: column;
      margin-top: 3%;
      text-align: left;
      width: 100%;

      &__title {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        width: 100%;

        &__circle {
          background-color: color.$grey-light;
          border-radius: 50%;
          height: 10px;
          margin-right: 10px;
          width: 10px;
        }

        &__text {
          cursor: pointer;
        }
      }

      &__category {
        background-color: color.$secondary-color;
        border-radius: 20px;
        color: color.$white-color !important;
        margin-top: 3%;
      }

      &__textarea {
        margin-bottom: 2%;
        margin-top: 4%;
      }

      &__label {
        font-weight: bold;
        margin-bottom: 2%;
        margin-top: 4%;
        text-align: left;

        &:first-child {
          margin-top: 0;
        }
      }

      &__select {
        border-radius: 20px;

        &:hover .ant-select-selector {
          border-color: #2994e6; /* Cambiar color del borde al hacer hover */
        }
      }

      &__date {
        color: color.$grey-intermediate;
        display: flex;
        flex-direction: row;

        &__label {
          text-decoration: underline;
        }

        &__date-picker {
          width: 25vw;
        }

        &__hour {
          width: 20%;
        }

        &__recurrency {
          border: 0;
        }

        &__time-label {
          margin-left: 1%;
          text-decoration: underline;
        }

        &__time-selector {
          background-color: color.$grey-background;
        }
      }

      &__recurrency {
        color: color.$grey-intermediate;
        display: flex;
        flex-direction: row;
        margin-top: 2%;
        width: 100%;

        &__details {
          align-items: center;
          display: flex; // Cambiar a flex
          flex-direction: column;
          justify-content: space-between;
          width: 15vw;

          &__section {
            align-items: center;
            display: flex;
            flex-direction: row;
            width: 100%;

            &__weeks {
              width: 25%;
            }

            &__days {
              background-color: color.$grey-background;
              display: grid;
              grid-template-columns: repeat(7, 1fr);
              margin-left: 10px;
              width: 70%;

              &__item {
                border: 1px solid color.$grey-light;
              }
            }
          }

          &__header {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-top: 2%;
            width: 100%;

            &__label {
              font-weight: bold;
              margin-left: 4%;
            }
          }
        }
      }

      &__assign {
        color: color.$grey-intermediate;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        margin-top: 2%;
        width: 100%;

        &__checkbox {
          align-items: center;
          display: flex;
          flex-direction: row;

          &__label {
            margin-left: 2%;
          }
        }

        &__select {
          border-radius: 20px;
          margin-left: 5%;
          width: 90%;
        }
      }

      &__alert {
        color: color.$danger-color;
        margin-top: 4%;
      }

      &__btns {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 4%;
        width: 100%;
      }

      &__material-label-img {
        height: 20px;
        margin-right: 2%;
      }
    }
  }
}

.day-active {
  background-color: color.$grey-light; // Cambia este color según tu diseño
  color: color.$grey-background;
}

.ant-select-selection-item,
.ant-select-selection-placeholder,
.ant-select-selection-search input {
  font-size: 0.9vw !important;
}

// Para dispositivos móviles
@media screen and (max-width: mixins.$mobile-max-width) {
  .ant-select-selection-item,
  .ant-select-selection-placeholder,
  .ant-select-selection-search input {
    font-size: 11px !important;
  }
}
