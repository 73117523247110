@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.profile-body {
  height: 100% !important;
  overflow: scroll;

  &__summary {
    border-bottom: 3px color.$grey-background;
    border-bottom-style: dotted;
    height: 60% !important;
    margin-bottom: 8%;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 380px;
    position: relative;
    text-align: left;
    width: 80%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      height: 230px !important;
      margin-bottom: 15%;
      min-height: 230px;
    }

    &__user {
      display: flex;
      height: 80%;
      width: 100%;

      @media screen and (max-width: mixins.$mobile-max-width) {
        height: 180px !important;
      }

      &__img-container {
        border-radius: 50%;
        height: 12vw;
        margin-top: 5%;
        overflow: hidden;
        width: 12vw;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 150px;
          width: 150px;
        }

        &__img {
          display: block; /* Ajusta la imagen al centro verticalmente */
          height: auto; /* Altura de la imagen se ajusta proporcionalmente al ancho */
          margin: 0 auto;
          width: 100%; /* Ancho de la imagen */
        }
      }

      &__info-container {
        height: 50%;
        margin-left: 5%;
        margin-top: 10%;
        width: 50%;

        &__name {
          color: color.$black-color;
          font-weight: font.$title-weight;
          height: 20px;
        }

        &__rol {
          color: color.$grey-dark;
          height: 20px;
        }

        &__mail {
          color: color.$grey-dark;
          height: 20px;
          margin-left: 10%;
          margin-top: 10%;
        }

        &__phone {
          color: color.$grey-dark;
          height: 20px;
          margin-left: 10%;
        }
      }
    }

    &__others {
      bottom: 5%;
      display: block;
      height: 25%;
      justify-items: right !important;
      margin-top: 10%;
      position: absolute;
      right: 0%;
      width: 30%;

      &__link {
        color: color.$grey-light;
        display: block;
        float: right;
        font-size: 12px;
        justify-content: right;
        text-decoration: none;
      }
    }
  }
}
