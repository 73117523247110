@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.records {
  align-items: center; /* Centrar verticalmente */
  background-color: color.$white-color;
  border-radius: 15px;
  display: flex;
  height: 100%;
  width: 100%;
}
