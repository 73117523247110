@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;
@use "../../scss/includes/mixins" as mixins;

.settings-grid-container {
  display: grid;
  grid-auto-rows: 8%;
  grid-gap: 3.5%;
  grid-template-columns: repeat(3, 1fr);
  height: 85%;
  margin-left: 2.5%;
  min-height: 380px;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__optionItem {
    align-items: center;
    background-color: color.$grey-background;
    border-radius: 10px;
    color: color.$grey-dark;
    display: flex;
    height: 100%;

    &__textContainer {
      align-items: center;
      display: flex;
      height: 100%;
      width: 70%;

      &__title {
        margin-left: 2%;
        text-decoration: none !important;
      }
    }

    &__imgContainer {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 30%;

      &__img {
        height: 60% !important;
        margin-left: 5%;
        margin-right: 5%;
        max-width: 70%;
      }
    }
  }

  &__optionItem:hover {
    border: 1px solid color.$primary-color;
    border-radius: 10px;
  }
}

.settings {
  color: color.$black-color;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: space-between;
  margin: 0 auto !important;
  margin-top: 2%;
  min-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    padding: 1.5%;
  }

  &__grid-container {
    display: grid;
    gap: 4%;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    overflow-y: auto;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__item {
      background-color: color.$white-color;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      text-align: left;
      width: 100%;

      &__title {
        align-items: center;
        color: font.$subtitle-color;
        display: flex;
        font-size: font.$subtitle-size;
        font-weight: bold !important;
        height: 3%;
        margin-bottom: 3%;
        width: 100%;

        &__section1 {
          width: 30%;
        }

        &__section2 {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          width: 70%;

          &__item {
            color: font.$subtitle-color !important;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }

      &__list {
        border: 3px solid color.$grey-background;
        border-radius: 15px;
        height: 70% !important;
        margin-bottom: 2%;
        overflow-y: auto;
        position: relative;

        &__search {
          align-content: center;
          background-color: color.$white-color;
          border-radius: 15px;
          box-shadow: 0 3px 6px #00000029;
          color: color.$white-color;
          display: flex;
          height: 10%;
          margin-bottom: 2%;
          min-height: 30px;
          padding: 2%;
          position: sticky;
          text-align: center;
          top: 0;
          width: 100% !important;
          z-index: 1;

          &__searchbar {
            background-color: color.$white-color;
            border: 0;
            color: color.$grey-intermediate !important;
            height: 100% !important;
            outline: none;
            text-align: left;
            width: 100%;
          }

          &__searchbar::placeholder {
            color: color.$grey-light; /* Reemplaza "#aaa" con el color deseado */
          }

          &__icon {
            float: right;
            margin-top: 2.5%;
            width: 15%;

            &__img {
              max-width: 30px;
              width: 50%;
            }
          }
        }

        &__element {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__data {
            align-items: center;
            border-bottom: 1px solid color.$grey-background;
            color: color.$grey-intermediate;
            display: flex;
            flex-direction: row;
            justify-content: left;
            padding: 2% 3%;
            width: 100%;

            &__btn {
              cursor: pointer;
            }

            &__text {
              align-items: center;
              display: flex;
              height: 100% !important;
              margin-left: 1%;
              margin-right: 1%;
              width: 83%;
            }

            &__dropdown {
              height: 100%;
              margin-right: auto;
            }

            &__checkbox {
              height: 100% !important;
              margin-right: 3%;
            }
          }

          &__readOnly {
            align-items: center;
            border-bottom: 1px solid color.$grey-background;
            color: color.$grey-intermediate !important;
            display: flex;
            flex-direction: row;
            justify-content: left;
            padding: 2% 3%;
            width: 100%;

            &__btn {
              cursor: pointer;
            }

            &__text {
              display: flex;
              flex: 1; /* El texto ocupa todo el espacio disponible */
              margin-left: 2%;
            }

            &__dropdown {
              margin-right: auto;
            }

            &__checkbox {
              height: 100%;
              margin-right: 2%;
            }

            &__logo {
              margin-right: auto;
            }
          }
        }
      }

      &__btns {
        display: flex;
        flex-direction: row;
        height: auto;
        justify-content: space-between;
        padding: 4%;

        &__item {
          background-color: color.$grey-intermediate;
          width: 40%;
        }
      }

      &__content {
        align-items: center;
        background-color: color.$grey-background;
        border: 3px solid color.$grey-background;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5%;
        padding: 5%;
        width: 100% !important;
      }
    }

    &__item_right {
      background-color: color.$white-color;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      text-align: left;
      width: 100%;

      &__title {
        align-items: center;
        color: font.$subtitle-color;
        display: flex;
        font-size: font.$subtitle-size;
        font-weight: bold;
        height: 3%;
        margin-bottom: 3%;
        width: 100%;

        &__text {
          margin-right: 2%;
        }
      }

      &__legend {
        display: flex;
        flex-direction: column;
        margin-bottom: 5%;

        &__item {
          color: color.$grey-light;
          display: flex;
          flex-direction: row;
          font-weight: bold;
          line-height: 2;

          &__text {
            margin-left: 3%;
          }
        }
      }

      &__preview {
        border: 3px solid color.$primary-color;
        border-radius: 15px;
        color: color.$primary-color !important;
        height: 70% !important;
        margin-bottom: 5%;
        overflow-y: auto;
        padding: 3%;
        position: relative;
      }

      &__previewBtn {
        background-color: color.$primary-color;
        border-radius: 15px !important;
        color: color.$white-color;
        height: 50px;
        margin-left: 10% !important;
        width: 80%;
      }

      &__logo {
        align-items: center;
        background-color: color.$grey-background;
        border-radius: 10px;
        display: flex;
        height: 13% !important;
        padding: 1% 3%;
        width: 100%;

        &__imgContainer {
          display: inline-block;
          height: 100%;
          width: 20%;

          &__img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        &__text {
          align-items: center;
          color: font.$subtitle-color !important;
          display: inline-block;
          display: flex;
          flex: 1; /* Hace que el texto tome todo el espacio restante */
          font-size: font.$subtitle-size;
          height: 100%;
          margin-left: 5%;
          margin-right: 5%;
          width: 25%;
        }

        &__btns {
          align-items: center !important;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          width: 35%;

          &__select {
            color: color.$grey-light;
            cursor: pointer;
            margin-bottom: 2%;
            text-align: right !important;
            text-decoration: underline;
            width: 100%;
          }
        }
      }

      &__content {
        align-items: center;
        background-color: color.$grey-background;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10%;
        padding: 5%;
        width: 100% !important;

        &__btn {
          display: flex; // Agrega esta línea para usar flexbox
          height: 10%;
          justify-content: space-between;
          margin-bottom: 1%;
          width: 100%;

          &__edit-button {
            background-color: color.$secondary-color;
          }

          &__delete-button {
            background-color: color.$grey-intermediate;
          }
        }
      }
    }
  }

  &__table-container {
    background-color: color.$grey-background;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgb(196 195 197 / 100%);
    height: 70%;
    position: relative;

    &__btns {
      align-items: center;
      bottom: 40px;
      display: flex;
      flex-direction: row;
      height: 10%;
      justify-content: right;
      padding: 7px;
      width: 100%;
      z-index: 1;
    }
  }

  &__calendarBtns {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 5% !important;
    justify-content: right;
    width: 100%;

    &__item {
      background-color: color.$primary-color;
      border-radius: 15px;
      color: color.$white-color;
      height: 100%;
      width: 15%;
    }
  }

  &__calendar {
    height: 95% !important;
    margin-bottom: 2%;
    width: 100%;
  }
}

.not-active {
  font-weight: normal !important;
}

.active {
  font-weight: bold;
}

.left {
  border-right: 1px solid color.$black-color;
  padding-right: 3%;
}

.right {
  margin-left: 3%;
}

.form-disabled {
  pointer-events: none;
}

.item-btn {
  align-items: center;
  border-radius: 15px !important;
  color: font.$button-color;
  cursor: pointer;
  display: flex;
  font-size: font.$button-size;
  height: 25px !important;
  justify-content: center;
  margin: auto !important;
  text-align: center;
  width: 45% !important;
}

.details-container {
  background-color: color.$white-color;
  border-radius: 15px;
  height: 99%;
  padding: 5%;
  width: 100%;

  &__title {
    color: color.$grey-dark;
    font-weight: bold;
  }

  &__item {
    color: color.$grey-intermediate;
  }
}
