@use "../../../../scss/includes/colors" as color;

.bagcard {
  border-radius: 15px;
  position: relative;

  &__header {
    background-color: color.$grey-background;
    height: 20%;
    width: 100%;
  }

  &__body {
    background-color: color.$white-color;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 25%;
    width: 100%;

    &__logo {
      display: inline-block;
      height: 40%;
      left: 30%;
      position: absolute;
      top: -2% !important;
      transform: translateX(-50%) !important;
    }

    &__alert {
      display: inline-block;
      height: 40%;
      left: 30%;
      margin-left: 35%;
      position: absolute;
      top: -2% !important;
      transform: translateX(-50%) !important;
    }

    &__past-month {
      margin-top: 10%;
      position: absolute;
      right: 5%;

      &__text {
        color: color.$grey-dark;
        display: block;
        font-size: 7px;
        justify-content: right !important;
        text-decoration: none;
      }
    }
  }

  &__content {
    background-color: color.$white-color;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 55%;
    width: 100%;

    &__kg {
      color: color.$grey-dark;
      font-size: 20px;
      font-weight: bold;
      margin-left: 10%;
    }

    &__name {
      color: color.$black-color;
      font-size: 10px;
      font-weight: bold;
      margin-left: 10%;
    }

    &__ver {
      color: color.$grey-light;
      font-size: 10px;
      line-height: 2;
      margin-left: 10%;
    }
  }
}
