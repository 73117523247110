@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/variable" as variable;
@use "../../../../scss/includes/fonts" as font;

.right-container {
  align-items: center;
  background-color: color.$white-color;
  display: flex;
  flex-direction: column;
  height: 100% !important;

  &__calendar {
    align-items: center;
    background-color: color.$white-color;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    display: flex;
    height: auto;
    justify-content: center;
    margin-top: 7%;
    position: relative;
    width: 90%;
  }

  &__messages {
    background-color: color.$white-color;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 40%;
    margin: 0 auto;
    overflow: scroll;
    width: 100%;

    &__title {
      color: color.$grey-intermediate;
      font-size: font.$title-size;
      margin: 0 auto;
      padding-bottom: 3%;
      padding-left: 5%;
      padding-top: 5%;
      width: 100%;
    }

    &__content {
      display: inline;
      margin-left: 5%;
      overflow-y: scroll;

      &__logo {
        margin-left: 5%;
        width: 5%;
      }

      &__text {
        color: color.$grey-intermediate;
        font-size: font.$subtitle-size;
        margin-left: 5%;
      }

      &__line {
        color: color.$grey-intermediate;
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}

.MuiPickersCalendarHeader-labelContainer {
  font-size: font.$subsubtitle-size !important;
  text-transform: uppercase;
  width: 80% !important;
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  aspect-ratio: 1 / 1;
  border-radius: 50% !important;
}

.MuiPickersArrowSwitcher-root {
  width: 34%;
}

.MuiPickersCalendarHeader-switchViewButton {
  font-size: 5px !important;
}

.MuiPickersCalendarHeader-root {
  margin-right: 0 !important;
  padding: 5% !important;
}

.MuiDayCalendar-monthContainer {
  color: color.$grey-intermediate !important;
}

.css-12p2adl-MuiDayCalendar-monthContainer {
  color: color.$grey-intermediate !important;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-qa7bje-MuiButtonBase-root.MuiPickersDay-root {
  color: color.$grey-intermediate !important;
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
  color: color.$grey-intermediate !important;
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  aspect-ratio: 1 / 1;
  background-color: color.$primary-color !important;
  border-radius: 50% !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 10%;
  margin-top: 3%;
  padding: 4%;

  &__item {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;

    &__logo {
      padding: 5%;
    }
  }
}

.content-wrapper {
  width: calc(100% - variable.$calendar-wrapper-width);
}

.ant-picker-content {
  font-size: font.$subtitle-size;
}
