@use "../../scss/includes/colors" as color;
@use "../../scss/includes/variable" as variable;
@use "../../scss/includes/mixins" as mixins;

.link-recover-pass {
  color: color.$white-color !important;
  display: block !important;
  padding: 10px 20px;
  text-align: center !important;
  text-decoration: underline !important;
  width: 100%;
}

.login_page {
  display: flex;
  height: 100%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    display: block;
  }

  &__item {
    width: 50%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      width: 100%;
    }

    &__title {
      color: color.$secondary-color;
      width: 250px;
    }
  }
}

.left-login {
  align-items: center;
  background-color: color.$white-color;
  color: color.$secondary-color;
  display: flex;
  justify-content: center;

  @media screen and (max-width: mixins.$mobile-max-width) {
    background-color: color.$secondary-color;
    padding: 20px;
  }
}

._login {
  @include mixins.form-separation-top-desktop;

  border-radius: 3px;
  padding: variable.$default-padding-form;

  &__title {
    background: transparent;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 20px;
    text-align: center;
    width: 100%;
  }

  &__wrapper {
    padding: 5px;
  }

  &__form {
    label {
      font-size: variable.$default-font-size !important;
    }

    .form-control {
      border-radius: 8px !important;
      color: color.$primary-color;
      padding: 15px !important;
    }
  }
}

.logo-inferior {
  bottom: 0;
  color: color.$white-color;
  display: inline-block;
  font-size: 10px;
  margin-bottom: 2%;
  margin-right: 2%;
  position: fixed;
  right: 0;
  width: 7%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    align-self: center;
    margin-bottom: 4%;
    margin-right: 4%;
    text-align: center;
    width: 17%;
  }
}
