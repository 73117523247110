@use "../../scss/includes/colors" as color;
@use "../../scss/includes/fonts" as font;

.control-panel-container {
  height: 90%;
  overflow: scroll;
  text-align: left;

  &__btn {
    align-items: center;
    background-color: color.$grey-intermediate;
    border-radius: 15px !important;
    color: font.$button-color;
    cursor: pointer;
    display: flex;
    font-size: font.$button-size;
    height: 25px !important;
    justify-content: center;
    margin: auto !important;
    text-align: center;
    width: 45% !important;
  }
}

.cp-body-grid-container {
  display: grid;
  gap: 1%;
  grid-template-columns: 52% 43%;
  height: 42%;
  margin: 0 auto;
  margin-left: 5%;
  width: 95%;
}

.cp-body-grid-container > div {
  background-color: color.$grey-background;
  border-radius: 15px;
  color: color.$black-color;
  display: inline-block;
  font-size: 15px;
  height: 100%;
}
