@use "../../../../scss/includes/colors" as color;
@use "../../../../scss/includes/fonts" as font;
@use "../../../../scss/includes/mixins" as mixins;

.registers {
  display: flex;
  flex-direction: column;
  height: 86%;
  margin: 0 auto;
  margin-top: 2%;
  min-height: 400px;
  overflow-y: auto;
  position: relative;
  text-align: left;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    height: auto;
    padding: 1.5%;
  }

  &__container {
    align-items: center;
    background-color: color.$white-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 650px;
    position: relative;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      height: 600px;
      margin-top: 20px !important;
    }

    &__filters {
      align-items: center;
      background-color: color.$grey-background;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      height: 15%;
      justify-content: space-between;
      margin-bottom: 1%;
      min-height: 50px;
      overflow-x: hidden;
      padding: 1% 2%;
      position: relative;
      width: 100% !important;

      @media screen and (max-width: mixins.$mobile-max-width) {
        align-items: flex-start;
        flex-direction: column;
        height: 30%;
        padding: 2%;
      }

      &__tipology {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 25%;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 40%;
          width: 50%;
        }

        &__title {
          align-items: center;
          color: color.$grey-intermediate;
          display: flex;
          font-weight: font.$title-weight;
          height: 40%;
          text-align: left;
          width: 100%;

          @media screen and (max-width: mixins.$mobile-max-width) {
            width: 100%;
          }
        }

        &__tipology-picker {
          background-color: color.$white-color;
          border-radius: 5px;
          color: font.$subtitle-color !important;
          float: right;
          font-size: font.$subtitle-size !important;
          height: 60%;
          width: 100% !important;

          @media screen and (max-width: mixins.$mobile-max-width) {
            width: 60%;
          }
        }
      }

      &__materials {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        position: relative;
        width: 100% !important;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 55%;
          width: 100% !important;
        }

        &__title {
          align-items: center;
          color: color.$grey-intermediate;
          display: flex;
          font-weight: font.$title-weight;
          height: 40%;
          text-align: left;
          width: 100%;

          &__checkbox {
            margin-right: 1%;
          }

          @media screen and (max-width: mixins.$mobile-max-width) {
            height: 30%;
            margin-left: auto;
            text-align: right;
          }
        }

        &__filter {
          background-color: color.$white-color;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          height: 60%;
          overflow-x: hidden;
          position: relative;
          width: 100% !important;

          @media screen and (max-width: mixins.$mobile-max-width) {
            height: 65%;
            margin-top: 2%;
            max-width: 100vw;
            width: 100%;
          }

          &__list {
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            height: 100%;
            margin-right: auto;
            overflow-x: hidden;
            white-space: nowrap;
            width: 90% !important;

            &__logo {
              height: 100%;

              &__unselected {
                height: 100%;
                opacity: 0.2;
              }
            }
          }

          &__arrow-btn {
            align-items: center;
            color: color.$grey-intermediate;
            cursor: pointer;
            display: flex;
            height: 90%;
            justify-content: center;
            text-align: center;
            width: 5%;
          }
        }
      }
    }

    &__data {
      background-color: color.$grey-background;
      border-radius: 15px;
      height: 77%;
      padding: 20px;
      position: relative;
      width: 100%;

      @media screen and (max-width: mixins.$mobile-max-width) {
        height: 70% !important;
        padding: 10px;
      }

      &__header {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 8% !important;
        margin-bottom: 2%;
        width: 100%;

        &__menu {
          align-items: center;
          background-color: color.$white-color;
          border-radius: 10px !important;
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          margin-right: 1%;
          width: 5%;
        }

        &__expandIcon {
          background-color: color.$primary-color;
          border-radius: 10px !important;
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          display: flex;
          flex-direction: row;
          height: 100% !important;
          margin-left: 1% !important;
          position: relative;
          width: 5% !important;

          &__img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        &__months {
          align-items: center;
          background-color: color.$white-color;
          border-radius: 10px !important;
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: space-between;
          padding: 1.5%;
          position: relative;
          width: 88%;

          &__title {
            color: color.$grey-dark;
            font-weight: bold;
            text-decoration: underline;
          }

          &__btn {
            align-items: center;
            border-radius: 25px !important;
            color: color.$grey-light;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            height: 25px;
            justify-content: space-between;
            padding-left: 3%;
            padding-right: 3%;
            width: 23%;
          }
        }
      }

      &__tableContainer {
        border-bottom: 2px solid color.$white-color;
        height: 90%;
        width: 100%;

        &__div {
          background-color: color.$white-color;
          border-radius: 5px !important;
          height: 100%;
          overflow-y: scroll;
          width: 100%;

          &__table {
            border-radius: 15px !important;
            height: 90%;
            overflow-y: auto;
            position: relative;
            table-layout: fixed;
            width: 100%;

            &__head {
              background-color: color.$primary-color;
              border-radius: 15px !important;
              color: color.$white-color;
              height: 3% !important;
              min-height: 30px;
              position: sticky !important;
              top: 0;

              &__row {
                border-radius: 15px !important;

                &__item {
                  background-color: color.$primary-color;
                  border-right: 0.2px solid color.$white-color;
                  overflow: hidden;
                  padding: 1%;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: calc(100% / 6);
                }
              }
            }

            &__body {
              background-color: color.$white-color;
              color: color.$grey-dark;
              overflow-y: auto;

              &__row {
                border-bottom: 1px solid color.$grey-background;

                &__item {
                  border-left: 0;
                  border-right: 1px solid color.$grey-background;
                  cursor: default;
                  overflow: hidden;
                  padding: 1%;
                  white-space: normal;
                  word-wrap: break-word;

                  &:last-child {
                    border-right: 0;
                  }

                  &__container {
                    align-items: center;
                    display: flex;
                    width: 100%;

                    &__img {
                      height: 10px;
                      margin-right: 5%;
                      width: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__btn {
      background-color: color.$primary-color;
      border-radius: 15px !important;
      color: color.$white-color;
      height: 5% !important;
      margin-top: 2% !important;
      width: 100% !important;
    }

    &__btns {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 5% !important;
      justify-content: flex-end;
      width: 100%;

      &__item {
        height: 100%;

        &__icon {
          align-self: flex-start;
          height: 100%;
          margin-right: auto;
        }
      }
    }
  }
}

@media screen and (max-width: mixins.$mobile-max-width) {
  .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-picker-cell-inner {
  font-size: 11px;
}

.expandedChart {
  align-items: center;
  background-color: color.$secondary-color;
  display: flex;
  height: 100vh; /* Cambié a viewport height */
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw; /* Cambié a viewport width */
  z-index: 100;

  &__container {
    align-items: center;
    background-color: color.$white-color;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 95vw !important;
    justify-content: center;
    margin: 0 !important;
    padding: 0% 3%;
    position: absolute;
    transform: rotate(90deg);
    transform-origin: center center;
    width: 90vh !important;

    &__chart {
      align-items: center;
      display: flex;
      height: 70vw;
      justify-content: center;
      width: 80vh;
    }
  }
}

.doc {
  width: 5%;
}

.files-popup {
  align-items: center;
  background: rgb(0 0 0 / 25%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__main {
    background-color: color.$white-color;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    display: block;
    height: auto !important;
    max-height: 80% !important;
    min-height: 150px !important;
    overflow: scroll;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    position: relative;
    width: 30%;

    &__title {
      align-items: center;
      background-color: color.$white-color;
      border-bottom: 1px solid color.$grey-intermediate;
      color: color.$grey-dark;
      display: flex;
      flex-direction: row;
      font-size: font.$title-size;
      font-weight: font.$title-weight;
      justify-content: space-between;
      margin-bottom: 3%;
      padding: 3% 0%;
      position: sticky;
      text-align: left;
      top: 0;

      &__text {
        left: 0;
      }

      &__close {
        color: color.$grey-intermediate;
        cursor: pointer;
      }
    }

    &__text {
      align-items: center;
      color: color.$grey-dark;
      display: flex;
      flex-direction: row;
      font-size: font.$subtitle-size;
      margin-top: 5%;
      text-align: left;

      &__icon {
        color: color.$grey-intermediate;
        margin-right: 1.5%;
      }

      &__label {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.last-of-week {
  border-bottom: 3px solid color.$primary-color;
}
