@use "../../../scss/includes/colors" as color;
@use "../../../scss/includes/fonts" as font;
@use "../../../scss/includes/mixins" as mixins;

.garbagecontrol {
  display: flex;
  flex-direction: column;
  height: 86%;
  margin: 0 auto;
  margin-top: 2%;
  min-height: 400px;
  overflow-y: auto;
  position: relative;
  text-align: left;
  width: 95%;

  @media screen and (max-width: mixins.$mobile-max-width) {
    height: auto;
    padding: 1.5%;
  }

  &__chart-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 43%;
    margin-bottom: 3%;
    min-height: 600px;
    position: relative;
    width: 100%;

    @media screen and (max-width: mixins.$mobile-max-width) {
      height: 350px;
      margin-top: 20px !important;
    }

    &__filters {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      height: 12%;
      margin-bottom: 1%;
      min-height: 50px;
      overflow-x: hidden;
      position: relative;
      width: 100% !important;

      @media screen and (max-width: mixins.$mobile-max-width) {
        align-items: flex-start; // Alinea el contenido a la izquierda
        flex-direction: column-reverse;
        height: 30%;
        justify-content: flex-start;
        margin-bottom: 3%;
      }

      &__bags {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        position: relative;
        width: 70% !important;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 50%;
          width: 100% !important;
        }

        &__title {
          align-items: center;
          color: color.$grey-intermediate;
          display: flex;
          font-weight: font.$title-weight;
          height: 40%;
          text-align: left;
          width: 100%;

          &__checkbox {
            margin-right: 1%;
          }

          @media screen and (max-width: mixins.$mobile-max-width) {
            height: 30%;
            margin-left: auto;
            text-align: right;
          }
        }

        &__filter {
          background-color: color.$grey-background;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          height: 60%;
          overflow-x: hidden;
          position: relative;
          width: 100% !important;

          @media screen and (max-width: mixins.$mobile-max-width) {
            height: 65%;
            margin-top: 2%;
            max-width: 100vw;
            width: 100%;
          }

          &__list {
            background-color: color.$grey-background;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            height: 100%;
            margin-right: auto;
            overflow-x: hidden;
            white-space: nowrap;
            width: 90% !important;

            &__logo {
              height: 100%;

              &__unselected {
                height: 100%;
                opacity: 0.2;
              }
            }
          }

          &__arrow-btn {
            align-items: center;
            color: color.$grey-intermediate;
            cursor: pointer;
            display: flex;
            height: 90%;
            justify-content: center;
            text-align: center;
            width: 5%;
          }
        }
      }

      &__date-filter {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: auto;
        width: 25%;

        @media screen and (max-width: mixins.$mobile-max-width) {
          height: 50%;
          width: 50%;
        }

        &__title {
          color: color.$grey-intermediate;
          font-weight: font.$title-weight;
          height: 30%;
          text-align: right;
          width: 100%;

          @media screen and (max-width: mixins.$mobile-max-width) {
            width: 100%;
          }
        }

        &__date-picker {
          color: font.$subtitle-color !important;
          float: right;
          font-size: font.$subtitle-size !important;
          height: 40%;
          width: 100% !important;

          @media screen and (max-width: mixins.$mobile-max-width) {
            width: 60%;
          }
        }
      }
    }

    &__chart {
      background-color: color.$grey-background;
      border-radius: 15px;
      height: 85%;
      overflow-y: auto;
      padding: 20px;
      position: relative;
      width: 100%;

      canvas {
        background-color: color.$white-color;
        border-radius: 15px;
        grid-gap: 10px;
        justify-content: center !important;
        overflow-x: scroll;
        padding: 10px;
        width: 100%;
      }

      @media screen and (max-width: mixins.$mobile-max-width) {
        height: 250px !important;
        padding: 10px;
      }
    }
  }

  &__costs {
    color: color.$black-color;
    display: flex;
    flex-direction: column;
    height: 54%;
    min-height: 800px;

    @media screen and (max-width: mixins.$mobile-max-width) {
      display: block;
    }

    &__title {
      font-weight: bold;
      height: 20px;
    }

    &__subtitle {
      font-weight: bold;
      height: 27px;
    }

    &__content {
      background-color: color.$grey-background;
      border-radius: 15px;
      box-shadow: 0 3px 6px rgb(196 195 197 / 50%);
      color: color.$grey-dark;
      display: flex;
      flex-direction: column;
      height: calc(96% - 47px);
      margin-bottom: 2%;
      margin-top: 2%;
      position: relative;
      width: 100%;

      &__message {
        align-items: center;
        color: color.$grey-light;
        display: flex;
        font-weight: bold;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      &__header {
        align-items: center;
        border-bottom: 2px solid color.$white-color;
        display: flex;
        flex-direction: row;
        height: 10% !important;
        padding: 2%;
        width: 100%;

        &__expandIcon {
          display: flex;
          flex-direction: row;
          height: 100% !important;
          margin-right: 1% !important;
          position: relative;
          width: 7% !important;

          &__img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        &__months {
          align-items: center;
          background-color: color.$white-color;
          border-radius: 15px;
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: space-between;
          padding: 1.5%;
          position: relative;
          width: 92%;

          &__title {
            font-weight: bold;
            text-decoration: underline;
          }

          &__btn {
            align-items: center;
            border-radius: 25px !important;
            color: color.$grey-light;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            height: 25px;
            justify-content: space-between;
            padding-left: 3%;
            padding-right: 3%;
            width: 23%;
          }
        }
      }

      &__header-expand {
        align-items: center;
        border-bottom: 2px solid color.$white-color;
        display: flex;
        flex-direction: row;
        height: 10% !important;
        padding: 1%;
        width: 100%;
      }

      &__summary {
        align-items: center;
        border-bottom: 2px solid color.$white-color;
        display: flex;
        flex-direction: row;
        height: 20%;
        padding: 1.5%;
        position: relative;
        width: 100%;

        &__totals {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          width: 40%;

          &__item {
            background-color: color.$white-color;
            border-radius: 10px;
            box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
            display: flex;
            flex-direction: row;
            height: 47%;

            &__title {
              border-right: 1px solid color.$grey-dark;
              display: flex;
              flex-direction: column;
              font-weight: bold;
              justify-content: center;
              padding: 3%;
              width: 60%;

              &__subtitle {
                font-weight: normal;
              }
            }

            &__number {
              align-items: center;
              display: flex;
              font-weight: bold;
              justify-content: space-between;
              padding: 3%;
              width: 40%;
            }
          }
        }

        &__detail {
          background-color: color.$white-color;
          border-radius: 15px;
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          display: flex;
          flex-direction: column;
          height: 100%;
          margin-left: 2%;
          width: 58%;
          z-index: 4;

          &__title {
            align-items: center;
            border-bottom: 1px solid color.$grey-dark;
            display: flex;
            flex-direction: row;
            font-weight: bold;
            height: 25% !important;
            justify-content: space-between;
            padding: 2%;

            &__unit {
              align-items: center;
              display: flex;
              flex-direction: row;
              font-weight: normal;
            }
          }

          &__row {
            border-bottom: 1px dashed color.$grey-light;
            display: grid;
            grid-template-columns: 40% 15% 15% 15% 15%;
            height: 25% !important;

            &:last-child {
              border-bottom: 0;
            }

            &__item {
              align-items: center;
              border-right: 1px solid color.$grey-light;
              display: flex;
              padding-left: 8%;

              &:last-child {
                border-right: 0;
              }

              &__title {
                font-weight: bold;
                overflow: hidden; // Oculta el contenido que se desborda
                text-overflow: ellipsis;
                white-space: nowrap; // Evita que el texto se rompa en varias líneas
              }
            }
          }
        }
      }

      &__tableContainer {
        border-bottom: 2px solid color.$white-color;
        height: 34%;
        padding: 1.5% !important;
        width: 100%;

        &:last-child {
          border-bottom: 0;
        }

        &__div {
          background-color: color.$white-color;
          height: 100%;
          overflow-y: scroll;
          width: 100%;

          &__table {
            border-bottom: 1px solid color.$grey-light;
            height: auto;
            position: relative;
            table-layout: fixed;
            width: 100%;

            &__head {
              background-color: color.$glass-color;
              color: color.$white-color;
              height: 8% !important;
              min-height: 30px;
              position: sticky !important;
              top: 0;
              z-index: 3;

              &__row {
                background-color: color.$glass-color;

                &__item {
                  background-color: color.$glass-color;
                  border-right: 0.2px solid color.$white-color;
                  padding: 1%;
                  width: calc(100% / 6);

                  &__content {
                    display: flex;
                    flex-direction: row;
                  }
                }
              }
            }

            &__body {
              background-color: color.$white-color;
              overflow-y: auto;

              &__row {
                &__item {
                  border-left: 0;
                  border-right: 1px solid color.$grey-light;
                  padding: 1%;

                  &:last-child {
                    border-right: 0;
                  }

                  &__container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: right;
  }

  &__detail {
    color: color.$black-color;
    height: auto;
    width: 50vw;

    &__title {
      font-weight: bold;
    }

    &__subtitle {
      border-bottom: 1px solid color.$grey-intermediate;
      font-weight: bold;
      height: 30px;
      margin-bottom: 2%;
    }

    &__content {
      height: 68%;
      margin-bottom: 1%;
      padding: 1.5% !important;
      width: 100%;

      &__tableContainer {
        background-color: color.$white-color;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgb(196 195 197 / 50%);
        height: 100%;
        overflow-y: scroll;
        width: 100%;

        &__table {
          height: 100%;
          position: relative;
          table-layout: fixed;
          width: 100%;

          &__head {
            background-color: color.$grey-light;
            color: color.$white-color;
            height: 30px !important;
            position: sticky !important;
            top: 0;

            &__row {
              background-color: color.$grey-light;

              &__item {
                background-color: color.$grey-intermediate;
                border-right: 1px solid color.$white-color;
                padding: 1%;
                width: calc(100% / 6);

                &:last-child {
                  border-right: 0;
                }
              }
            }
          }

          &__body {
            background-color: color.$white-color;
            overflow-y: auto;

            &__row {
              &__item {
                border-right: 1px solid color.$grey-light;
                padding: 1%;
                width: 100%;

                &:last-child {
                  border-right: 0;
                }

                &__input {
                  border: 1px solid color.$grey-intermediate;
                  border-radius: 5px;
                  padding: 2%;
                  width: 100%;
                }

                &__icons {
                  align-items: center;
                  display: flex;
                  flex-direction: row;
                  height: 100%;
                  justify-content: space-between;
                  width: 100%;
                }
              }

              &:last-child {
                border-top: 2px solid color.$grey-intermediate;
              }
            }
          }
        }
      }
    }

    &__buttons {
      height: 30px !important;
    }
  }

  &__consumption {
    color: color.$black-color;
    width: 23vw;

    &__title {
      font-weight: bold;
    }

    &__subtitle {
      border-bottom: 1px solid color.$grey-intermediate;
      font-weight: bold;
      height: 30px;
      margin-bottom: 5%;
    }

    &__content {
      height: 68%;
      padding: 1.5% !important;
      width: 100%;

      &__button {
        align-items: center;
        border-radius: 10px !important;
        box-shadow: 0 3px 6px rgb(196 195 197 / 50%);
        cursor: pointer;
        display: flex;
        height: 35px !important;
        justify-content: center;
        margin-bottom: 5% !important;
        padding: 3%;
        width: 100%;

        &:hover {
          background-color: color.$grey-intermediate;
          color: color.$white-color;
        }
      }

      &__value {
        border-bottom: 1px solid color.$grey-background;
        margin-bottom: 5%;

        &__method1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 5%;
          padding-left: 2%;
          padding-right: 2%;
          width: 100%;
        }

        &__method2 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 5%;
          padding-left: 2%;
          padding-right: 2%;
          width: 100%;

          &__label {
            margin-bottom: 2%;
          }

          &__input {
            border: 1px solid color.$grey-intermediate;
            border-radius: 5px;
            padding: 2%;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      height: 30px !important;
      justify-content: space-between;
    }

    &__popover {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 15vw;

      &__title {
        font-weight: bold;
      }

      &__subtitle {
        border-bottom: 1px solid color.$grey-intermediate;
        font-weight: bold;
        height: 30px;
        margin-bottom: 5%;
      }

      &__button {
        align-items: center;
        border-radius: 10px !important;
        box-shadow: 0 3px 6px rgb(196 195 197 / 50%);
        cursor: pointer;
        display: flex;
        height: 35px !important;
        justify-content: center;
        margin-bottom: 5% !important;
        padding: 3%;
        width: 100%;

        &:hover {
          background-color: color.$grey-intermediate;
          color: color.$white-color;
        }
      }
    }
  }

  &__popover {
    z-index: 2 !important;
  }
}

.garbage-expand {
  background-color: color.$grey-background;
  box-shadow: 0 3px 6px rgb(196 195 197 / 50%);
  color: color.$grey-dark;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.bags-column {
  text-align: center;
  width: 40%;
}

.bag-item {
  background-color: color.$grey-light;
}

.number-column {
  font-weight: bold;
  text-align: center;
  width: 5%;
}

.icons-column {
  font-weight: bold;
  text-align: center;
  width: 7%;
}

.cost-column {
  width: 23%;
}

.description-column {
  width: 35%;
}

.detail-column {
  width: 35%;
}

.method-active {
  background-color: color.$grey-intermediate;
  color: color.$white-color;
}

@media screen and (max-width: mixins.$mobile-max-width) {
  .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-picker-cell-inner {
  font-size: 11px;
}

.expandedChart {
  align-items: center;
  background-color: color.$secondary-color;
  display: flex;
  height: 100vh; /* Cambié a viewport height */
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw; /* Cambié a viewport width */
  z-index: 100;

  &__container {
    align-items: center;
    background-color: color.$white-color;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 95vw !important;
    justify-content: center;
    margin: 0 !important;
    padding: 0% 3%;
    position: absolute;
    transform: rotate(90deg);
    transform-origin: center center;
    width: 90vh !important;

    &__chart {
      align-items: center;
      display: flex;
      height: 70vw;
      justify-content: center;
      width: 80vh;
    }
  }
}

.unselected {
  opacity: 0.2;
}

.tableContainerTall {
  height: 68% !important;
}

.highlight {
  background-color: rgb(101 192 90 / 10%) !important;
}
