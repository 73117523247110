.pdfviewer-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.pdfviewer-container__content {
  max-width: 100%;
  width: 800px;
}
